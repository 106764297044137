import {lazy} from 'react';
import {Outlet, RouteObject} from 'react-router-dom';

const MainIndex = lazy(()=>import('../pages'));
const Home = lazy(()=>import('../pages/Home'));
const Introduction = lazy(()=>import('../pages/Introduction'));
const Download = lazy(()=>import('../pages/Download'));
const About = lazy(()=>import('../pages/About'));
const News = lazy(()=>import('../pages/News'));
const NewsContent = lazy(()=>import('../pages/News/NewsContent'));

const routerCfg:RouteObject[] = [
    {
        path:'/',
        element:<MainIndex/>,
        children:[
            {
                index : true,
                element : <Home />,
            },
            {
                path:'Introduction',
                element: <Introduction />
            },
            {
                path:'DownloadApp',
                element: <Download />
            },
            {
                path:'News',
                element: (<><Outlet /></>),
                children:[
                    {
                        index : true,
                        element:<News NewsType='companyNews'/>,
                    },
                    {
                        path:':newsId',
                        element:<NewsContent />,
                    }
                ]
            },
            {
                path:'About',
                element: <About />
            },
        ]
    }
];

export default routerCfg;