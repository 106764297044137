import React,{useEffect} from "react";
import nProgress from "nprogress";
import "nprogress/nprogress.css";

const PageLoadProgress:React.FC = ()=>{
    useEffect(() => {
        nProgress.start();
        return () => {
            nProgress.done();
        }
    }, [])
    return(
        <React.Fragment></React.Fragment>
    );
}

export default PageLoadProgress;