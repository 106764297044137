import {Suspense} from "react";
import {useRoutes} from 'react-router-dom';
import routerCfg from './router';
import PageLoadProgress from './components/pageLoadProgress';
import 'antd/dist/antd.less';
import './style/zfmateCloudSteel.less';

const App = ()=>{
  return (
    <Suspense fallback={<PageLoadProgress />}>
      {useRoutes(routerCfg)}
    </Suspense>
  )
};

export default App;
